#root {
  display: flex;
  flex: 1;
  width: 100%;
}

#root-container {
  padding: 16px;
  display: flex;
  flex: 1;
}
