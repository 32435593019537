@import '~carbon-components/scss/globals/scss/styles.scss';
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

html {
  height: 100%;
  -webkit-appearance: none;
}

body {
  margin: 0;
  height: 100%;
  font-family: "Raleway", "Segoe UI", "Roboto", "Oxygen", -apple-system, BlinkMacSystemFont, 
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
